const nodeRedUrl = '/api/v1/logs';

const parseStackTrace = (stack) => {
  const stackLines = stack.split('\n');
  for (let line of stackLines) {
    const match = line.match(/\s+at\s+(\w+)\s+\((.*):(\d+):(\d+)\)/);
    if (match) {
      const [_, component, file, lineNumber, columnNumber] = match;
      return { component, file, lineNumber, columnNumber };
    }
  }
  return { component: 'Unknown component', lineNumber: 'Unknown line number' };
};

export const sendLog = async (level, message, details = {}) => {
  const log = {
    timestamp: new Date().toISOString(),
    level,
    message,
    details,
  };

  try {
    await fetch(nodeRedUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(log),
    });
  } catch (error) {
    console.error('Failed to send log:', error);
  }
};

export const logError = (message, details) => sendLog('ERROR', message, details);
export const logWarning = (message, details) => sendLog('WARNING', message, details);